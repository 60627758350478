:root
  --primary-color: #323232
  --color-primary: #323232
  --primary-r: 50
  --primary-g: 50
  --primary-b: 50
  --primary-h: 0
  --primary-s: 0%
  --primary-l: 19.6%
  --switch: 100%
:root
  --secondary-color: #D98600
  --color-secondary: #D98600
